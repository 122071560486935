import React, { useState } from "react"
import styles from "./Layout.module.css"
import stylesContainer from "./SecondContainer.module.css"

const SecondContainer = () => {
  return (
    <div className={stylesContainer.main}>
      <div className={stylesContainer.secondary}>
        <div className={stylesContainer.leftSide}>
          <p style={{ color: "white" }}>Start a conversation</p>
          <button className={stylesContainer.buttonMail}>
            <h1>contact@stackat.com</h1>
          </button>
        </div>
        <div className={stylesContainer.rightSide}>
          <a href="">
            <h1 className={stylesContainer.buttonsRightSide}>About</h1>
          </a>
          <a href="">
            <h1 className={stylesContainer.buttonsRightSide}>Projects</h1>
          </a>
          <a href="">
            <h1 className={stylesContainer.buttonsRightSide}>Contact</h1>
          </a>
        </div>
      </div>
    </div>
  )
}

const Layout = ({ children }) => {
  const [menuVisibility, setMenuVisibility] = useState<null | boolean>(null)
  const setColor = () => {
    return { color: menuVisibility ? "white" : "black" }
  }

  return (
    // <h1>HOWDT</h1>
    <div className={styles.body}>
      <div
        style={{
          display: menuVisibility === null ? "none" : "block",
        }}
        className={`${styles.overlay} ${
          menuVisibility === true && styles.slideintop
        } ${menuVisibility === false && styles.slideouttop}`}
      >
        <SecondContainer />
      </div>

      <nav className={styles.nav}>
        <h3 style={{ ...setColor(), ...{ zIndex: 3 } }}>Stackat</h3>
        {/* right side of the navbar */}
        <div className={styles.rightSideButtons}>
          <button
            className={`${styles.button} ${
              menuVisibility === true && styles.textSlideTop
            } ${menuVisibility === false && styles.textSlideBot}`}
            style={setColor()}
            onClick={() => setMenuVisibility(v => !v)}
          >
            <h5>Menu</h5>
          </button>
          <div
            className={`${styles.hiddenLinks} ${
              menuVisibility === true && styles.textSlideBotUp
            } ${menuVisibility === false && styles.textSlideBotDown}`}
            style={{ display: menuVisibility === null ? "none" : "flex" }}
          >
            <button
              className={`${styles.buttonSecondary} ${styles.button}`}
              // style={{ ...setColor(), ...{ color: "#747474" } }}
            >
              <h5>LinkOne</h5>
            </button>
            {/* <button
              className={`${styles.buttonSecondary} ${styles.button}`}
              style={{ ...setColor(), ...{ color: "#747474" } }}
            >
              <h5>LinkOne</h5>
            </button> */}
            <button
              className={`${styles.buttonSecondary} ${styles.button}`}
              // style={{ ...setColor() }}
            >
              <h5>LinkTwo</h5>
            </button>
            <button
              className={styles.button}
              onClick={() => setMenuVisibility(v => !v)}
              style={setColor()}
            >
              <h5>Close</h5>
            </button>
          </div>
        </div>
      </nav>
      {children}
    </div>
  )
}

export default Layout
